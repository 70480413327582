<template>
  <app-layout class="page__recorder-outside">
    <instructions />
    <button
      class="button button_secondary record__sent-button"
      @click="showUploadingPopup"
      v-if="sent"
    >
      <span>Next</span>
      <span class="icon icon__arrow icon__arrow_right" />
    </button>
    <record-shared
      v-else
      @send-voice="sendLastHandler"
      :disabled="loading"
      :min-duration="minDuration"
      @stop="stopRecordingHandler"
      :voiceKey="voiceKey"
      :route="currentRoute"
      ref="record"
    />
  </app-layout>
</template>

<script>
import AppLayout from "@/components/appLayout";
import Instructions from "./components/instructions";
import RecordShared from "@/components/record";
import RecordMixin from "@/mixins/record";
import logVisiting from "@/mixins/logVisiting";

import { mapState, mapActions } from "vuex";
// import { getStorage, setStorage } from "@/modules/localStorage";
import { getStorage } from "@/modules/localStorage";
import { saveLog } from "@/modules/logger";
import { deregisterStream } from "@/api";

import {
  ROUTE_COUNT_TO_20,
  // ROUTE_FEELING_TODAY,
  POPUP_UPLOADING,
  RECORD_MIN_DURATION_CT,
  STORE_END_SESSION,
  STORE_SET_PASSED,
  POPUP_THANK_YOU,
  // ROUTE_PROFILE_HISTORY,
  ROUTE_PROFILE_HISTORY_DETAILED,
  STORE_FETCH_SESSION_QUALITY,
} from "@/constants";

export default {
  name: "CountTo20",
  components: { AppLayout, Instructions, RecordShared },
  mixins: [RecordMixin, logVisiting],

  inject: {
    showModal: { default: function () {} },
    closeModal: { default: function () {} },
  },

  // beforeRouteLeave(to, from, next) {
    // if (to.name === ROUTE_AUDIO_CALIBRATION) {
      // this[STORE_SET_PASSED]({
        // calibration: false,
        // sustainedAah: false,
        // threeDeepBreaths: false,
        // threeStrongCoughs: false,
        // readingPassage: false,
        // countTo20: false,
      // });
    // }
// 
    // this.closeModal(POPUP_THANK_YOU);
// 
    // next();
  // },

  data: () => ({
    voiceKey: "audio6",
    currentRoute: ROUTE_COUNT_TO_20,
    minDuration: RECORD_MIN_DURATION_CT,
    type: "record",
    sent: getStorage("ct_sent"),
  }),

  computed: {
    ...mapState(["uploading", "user"]),

    isEveryUploaded() {
      console.log("isEveryUploaded");
      if (!this.sent) return false;
      return Object.values(this.uploading).every(
        ({ uploaded, error }) => uploaded || error
      );
    },

    hasError() {
      console.log("error in handler for is every uploaded reached");
      return Object.values(this.uploading).some(({ error }) => error);
    },
  },

  watch: {
    isEveryUploaded: {
      immediate: true,
      async handler(value) {
        if (!value) return;
        console.log("handler for is every uploaded reached")

        try {
          if (!this.hasError) {
            await this.endSession();
          } else {
            this.onUploadedWithErrors();
          }

          this.fetchSessionQuality().then(() => {
            this.closeModal(POPUP_UPLOADING);
            // this.setPassed();
            this.setPassedFinal();
          });
        } catch ({ message }) {
          saveLog("session-end-error", { message });

          await this[STORE_SET_PASSED]({ [ROUTE_COUNT_TO_20 ]: true });
          // await this[STORE_SET_PASSED]({ [ROUTE_FEELING_TODAY ]: true });
          const { user } = this;
          this.$router.push({ 
            name: ROUTE_PROFILE_HISTORY_DETAILED , params: {id: user.session_id} // routes to LPR for this session_id
          });  // TODO: route to lpr
        }
      },
    },
  },
  
    mounted(){
      console.log("Mounted count to 20 page");
      // var get_feeling_today = this.$store.state.user.get_feeling_today
      // if (!get_feeling_today) {
      //   console.log("will be setting feeling today uploaded to true")
      // }


    },

  methods: {
    ...mapActions([
      STORE_END_SESSION,
      STORE_FETCH_SESSION_QUALITY,
      STORE_SET_PASSED,
    ]),

    async setPassedFinal() {
      saveLog("session-end-successful");
      await this[STORE_SET_PASSED]({ [ROUTE_COUNT_TO_20 ]: true });
      // await this[STORE_SET_PASSED]({ [ROUTE_FEELING_TODAY ]: true });  // skipping feeling today if count is final
      const { user } = this;
      this.$router.push({ name: ROUTE_PROFILE_HISTORY_DETAILED ,
                          params: {id: user.session_id, sess_end:true}});  // TODO: route to lpr
      // this.showSuccessPopup();
    },
    async setPassed() {
      saveLog("count-finished-successful");
      await this[STORE_SET_PASSED]({ [ROUTE_COUNT_TO_20 ]: true });
      // this.$router.push({ name: ROUTE_FEELING_TODAY });
      // this.showSuccessPopup();
    },

    sendLastHandler(blob) {
      this.sent = true;
      this.sendVoice(blob);

      // var get_feeling_today = this.$store.state.user.get_feeling_today
      console.log("Send")
      // if (!get_feeling_today) {
      //   setStorage({ ct_sent: true });
      //   this.uploading["audio7"].uploaded = true;
      //   console.log("Setting feeling today uploaded to true")
      //   if (!Object.values(this.uploading).every(
      //     ({uploaded, error}) => uploaded || error
      //   ))
      //     this.showUploadingPopup();
      // }
      // else
        // this.setPassed();
      
      this.showUploadingPopup();
      this.setPassed();

    },

    showUploadingPopup() {
      return this.showModal(POPUP_UPLOADING);
    },

    showSuccessPopup() {
      console.log("show success pop up")
      const onCloseSuccessPopup = () =>
        console.log("Close pop up ")
        // this.$router.push({ name: ROUTE_PROFILE_HISTORY });
        const { user } = this;

        this.$router.push({ name: ROUTE_PROFILE_HISTORY_DETAILED ,
        params: {id: user.session_id} // routes to LPR for this session_id
         });


      return this.showModal(POPUP_THANK_YOU, {
        handleClose: onCloseSuccessPopup,
      });
    },

    endSession() {
      return this[STORE_END_SESSION]().then(
        deregisterStream(
          this.$store.state.user.session_id,
          this.$store.state.user.user_id,
        )
      );
    },

    onUploadedWithErrors() {
      const errors = Object.entries(this.uploading).reduce(
        (acc, [key, { error }]) => {
          if (!error) return acc;
          return `${acc} ${key}`;
        },
        ""
      );
      saveLog("record-unuploaded", { errors });
    },

    fetchSessionQuality() {
      return this[STORE_FETCH_SESSION_QUALITY]();
    },
  },
};
</script>

<style lang="scss" scoped>
.record__sent-button {
  position: fixed;
  z-index: 10;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
}
</style>
